import React from 'react';
import tw from 'twin.macro';
import KeyPoint from '../../components/key-point';
import ArticleLayout from '../../layouts/article';

const Image = tw.img`pt-4 md:pt-6 w-full`;

export default () => (
  <ArticleLayout
    category="service"
    title="跨平台 App 开发"
    subtitle="移动端 App / 桌面端应用 / 智能合约"
  >
    <KeyPoint
      index="○"
      title="范围"
      content="信息化管理系统的移动端应用；面向个人用户的 C2C 数字化平台；基于 Android 的系统级定制驱动或应用；Windows / Linux / macOS 的桌面系统应用；基于区块链的智能合约应用。"
    />
    <KeyPoint
      index="○"
      title="案例：齐装网"
      content="待客 App - 为家居装饰客户打造的 B2C 社区平台，基于兴趣向客户推荐家装设计师。"
    />
    <Image src="/showcase/deco.jpg" />
  </ArticleLayout>
);
